<planning-poker-landing-header></planning-poker-landing-header>
<div class="content">
  <router-outlet></router-outlet>
</div>
<div class="footer">
  <div class="link-columns">
    <div class="logo-column">
      <img
        ngSrc="Logo_Outline"
        placeholder
        alt="Planning Poker black and white logo"
        width="50"
        height="50" />
    </div>
    <div class="link-column">
      <span class="link-column-title">Integrations</span>
      <a routerLink="/integrations/teams">Microsoft Teams</a>
      <a routerLink="/integrations/meet">Google Meet</a>
      <a routerLink="/integrations/zoom">Zoom Meetings</a>
      <a routerLink="/integrations/webex">Webex Meetings</a>
      <a routerLink="/integrations/jira">Jira</a>
      <a routerLink="/integrations/linear">Linear</a>
      <a routerLink="/integrations/slack">Slack</a>
    </div>

    <div class="link-column">
      <span class="link-column-title">Articles</span>
      <a routerLink="/faq">FAQ</a>
      <a routerLink="/knowledge-base">Knowledge base</a>
      <a
        routerLink="/knowledge-base/planning-poker-guide-agile-estimation-techniques"
        >The Definitive Guide to Planning Poker</a
      >
      <a routerLink="/knowledge-base/essential-scrum-master-tools"
        >5 Essential Scrum Master Tools</a
      >
    </div>
    <div class="link-column">
      <span class="link-column-title">Tools</span>
      <a routerLink="/create">Planning Poker</a>
      <a routerLink="/tools/story-point-calculator">Story Point Calculator</a>
    </div>
    <div class="link-column">
      <span class="link-column-title">Policies</span>
      <a routerLink="/pricing">Pricing</a>
      <a routerLink="/policies/terms">Terms</a>
      <a routerLink="/policies/privacy">Privacy</a>
      <a href="https://github.com/biharygergo/card-estimator">Github</a>
    </div>
  </div>
</div>
